import {getZ_auth, putZ} from "@/api/axios";

import {resCards, resDeletedCard} from "@/types/cards";

import {unknown_error} from "@/api/api_url";

const customers_cards_url = 'customers/cards'
const customers_card_delete_url = (last_numbers) => {
  return `customers/cards/${last_numbers}`
}

async function CustomersCards() {
  const response: resCards = await getZ_auth(customers_cards_url)
  return response ? response : unknown_error
}

async function DeleteCustomersCard(last_numbers: string) {
  const response: resDeletedCard = await putZ(
    customers_card_delete_url(last_numbers)
)
  return response ? response : unknown_error
}

export {
  CustomersCards,
  DeleteCustomersCard
}