import axios from "axios";
import {api_url} from "@/api/api_url";
import {getTokenL, removeTokenL} from "@/api/auth";

/**
 * Общая функция запроса к API
 * @param url
 * @param params
 */
async function getZ(url: string, params: object | null = null) {
  let res
  await axios(
    {
      method: 'GET',
      url: api_url + url + "/",
      params: params,
      headers: {
        'Authorization': "Token ",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        'Access-Control-Allow-Credentials': "true",
      }
    }).then(
    (response) => {
      res = response.data
    }
  ).catch(
    (error) => res = Catch(error, res)
  )
  return res
}


async function postZ(
  url: string,
  data: object | null = null,
  auth = false) {
  let res: any
  await axios({
    method: 'post',
    url: api_url + url + "/",
    data: data,
    headers: {
      Authorization: auth ? 'Token ' + getTokenL() : ''
    }
  }).then(
    (response) => res = response.data
  ).catch(
    (error) => res = Catch(error, res)
  )
  return res
}

/**
 * Общая функция запроса к API c авторизацией
 * @param url
 * @param params
 */
async function getZ_auth(url: string, params: object | null = null) {
  let res
  await axios(
    {
      method: 'GET',
      url: api_url + url + "/",
      params: params,
      headers: {
        Authorization: 'Token ' + getTokenL(),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.origin,
        'Access-Control-Allow-Credentials': "true",
      }
    }).then(
    (response) => {
      res = response.data
    }
  ).catch(
    (error) => res = Catch(error, res)
  )
  return res
}

async function putZ(
  url: string,
  data: object | null = null,
  auth = true) {
  let res: any
  await axios({
    method: 'delete',
    url: api_url + url + "/",
    data: data,
    headers: {
      Authorization: auth ? 'Token ' + getTokenL() : ''
    }
  }).then(
    (response) => res = response.data
  ).catch(
    (error) => res = Catch(error, res)
  )
  return res
}

function Catch(error: any, res: any) {
  if (process.env.NODE_ENV === 'development') console.log(error.response)

  if (error.response && error.response.data.error) {
    res = error.response.data
  } else {
    if (
      error.response.status === 401 && error.response.data.detail === 'Invalid Token'
    ) {
      removeTokenL()
      res = {
        error: "Ошибка авторизации"
      }
    } else {
      res = {
        error: error.message
      }
    }
  }
  return res
}

export {
  getZ,
  postZ,
  getZ_auth,
  putZ
}