<template>
  <ion-grid v-if="node_env === 'development' || node_env === 'test'">
    <div v-if="node_env === 'development'" class="text_test_mode">
      ЛОКАЛЬНЫЙ РЕЖИМ
    </div>
    <div v-else-if="node_env === 'test'" class="text_test_mode">
      ТЕСТОВЫЙ РЕЖИМ
    </div>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
} from '@ionic/vue';

export default {
  name: "TestMode",
  components: {
    IonGrid
  },
  setup() {
    const node_env = process.env.VUE_APP_MODE
    return {
      node_env
    }
  }
}
</script>

<style scoped>
.text_test_mode {
  font-size: 46px;
  opacity: 20%;
}
</style>