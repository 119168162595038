import { getZ } from '@/api/axios'
import { unknown_error } from '@/api/api_url'

const banks_url = 'payment-gateways/gw/banks'

async function GetBanks() {
  const response = await getZ(banks_url)
  if (response) {
    return response
  }
  return unknown_error
}

export { GetBanks }
