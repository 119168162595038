<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid
          :class="is_mobile ? 'padding_mobile_main main_card' : 'padding_desktop_main main_card'"
          fixed
        >
          <slot/>
        </ion-grid>
        <TestMode></TestMode>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import {defineComponent} from 'vue';

import {
  IonPage, IonContent, IonGrid
} from '@ionic/vue';

import TestMode from "@/components/base/test-mode";

export default defineComponent({
  name: "BaseLayout",
  props: ['title', 'is_mobile'],
  components: {
    IonPage, IonContent, IonGrid,
    TestMode
  },
})
</script>