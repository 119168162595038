import { getZ } from '@/api/axios'

const dens_url = 'blockchains/everscale/get_address_by_name'

/**
 * Список всех валютных пар.
 */
async function getAdressByName(query?: string) {
  const response = await getZ(dens_url, { name : query })

  if (response) {
    return response
  }
  return []
}

export { getAdressByName }
