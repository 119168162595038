function GetKeyLS(bank: string): string {
  const key = localStorage.getItem(bank)
  return key ? key : ""
}

function CheckBankInLocalStorage(bank: string): number {
  const bank_1 = GetKeyLS('bank_1')
  const bank_2 = GetKeyLS('bank_2')

  let bank_in_ls
  if (bank === bank_1) bank_in_ls = 1
  else if (bank === bank_2) bank_in_ls = 2
  else bank_in_ls = 0


  if (localStorage.getItem('bank_3')) {
    if (bank_in_ls === 1) return 31
    else if (bank_in_ls === 2)  return 32
    else return 3
  }
  if (localStorage.getItem('bank_2')) {
    if (bank_in_ls === 1) return 21
    else if (bank_in_ls === 2)  return 22
    else return 2
  }
  if (localStorage.getItem('bank_1')) {
    if (bank_in_ls === 1) return 11
    else return 1
  }
  else return 0
}

const bank_1 = (bank: string) => localStorage.setItem('bank_1', bank)
const bank_2 = (bank: string) => localStorage.setItem('bank_2', bank)
const bank_3 = (bank: string) => localStorage.setItem('bank_3', bank)


function BankToLocalStorage(bank: string) {
  const place = CheckBankInLocalStorage(bank)
  switch (place) {
    case 11:
    case 21:
    case 31:
      break
    case 0:
      bank_1(bank)
      break
    case 1:
    case 22:
    case 32:
      bank_2(GetKeyLS('bank_1'))
      bank_1(bank)
      break
    case 2:
    case 3:
      bank_3(GetKeyLS('bank_2'))
      bank_2(GetKeyLS('bank_1'))
      bank_1(bank)
      break
  }

}


function GetBanksFromLS() {
  return [
    GetKeyLS('bank_1'),
    GetKeyLS('bank_2'),
    GetKeyLS('bank_3'),
  ]
}


export {
  BankToLocalStorage,
  GetBanksFromLS
}
