import { UnknownError } from '@/types/api'

let domen_url: string

if (process.env.NODE_ENV === 'development') {
  domen_url = 'http://127.0.0.1:8000/'
  // domen_url = 'https://admin.dev.converter.itpay.ru/'
  // domen_url = 'https://dev.converter.itpay.ru/'
} else {
  domen_url = process.env.VUE_APP_URL_BACKEND
}

const api_url = domen_url + 'v1/'

const unknown_error: UnknownError = {
  data: null,
  error: 'Unknown error.',
}

export { domen_url, api_url, unknown_error }
