import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import BuyCrypto from '../views/BuyCrypto.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/buycrypto',
  },
  {
    path: '/buycrypto',
    name: 'BuyCrypto',
    component: BuyCrypto,
  },
  {
    path: '/success_true',
    name: 'SuccessTrue',
    component: () => import('@/views/SuccessTrue.vue'),
  },
  {
    path: '/success_false',
    name: 'SuccessFalse',
    component: () => import('@/views/SuccessFalse.vue'),
  },
  {
    path: '/success_qr',
    name: 'SuccessTrueQR',
    component: () => import('@/views/SuccessTrueQR.vue'),
  },
  {
    path: '/test',
    name: 'TestView',
    component: () => import('@/views/TestView.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error.vue'),
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import('@/views/Redirect.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
