export const convertPaymentStatus = (status: string): number => {
  switch (status) {
    case 'EXPIRED' || 'ERROR' || 'CANCELLED' || 'ANTIFROD CHECK REJECTED':
      return -1
    case 'COMPLETED' || 'PAYMENT PAID BY USER':
      return 1
    default:
      return 0
  }
}
