import Vuex from 'vuex'

// import {DataPayment, DataResponsePaymentFull} from "@/types/payments";
import { Project } from '@/types/assets'

export const store = new Vuex.Store({
  state: {
    project: null as Project | null,
    banks: [] as Bank[],
    banks_all: [] as Bank[],
    banks_main: [] as Bank[],
    banks_others: [] as Bank[],
    is_other_banks: false as boolean,
    is_banks_loading: true,
    checked_bank: '' as string | null,
    is_wallet_status_loading: false as boolean,
    is_wallet_status_ok: false as boolean,
    is_wallet_status_error: false as boolean,
  },
  getters: {
    PROJECT: (state) => state.project,

    BANKS: (state) => state.banks,
    IS_BANKS_LOADING: (state) => state.is_banks_loading,
    CHECKED_BANK: (state) => state.checked_bank,
    BANKS_ALL: (state) => state.banks_all,
    BANKS_MAIN: (state) => state.banks_main,
    BANKS_OTHERS: (state) => state.banks_others,
    IS_OTHER_BANK: (state) => state.is_other_banks,

    IS_WALLET_STATUS_LOADING: (state) => state.is_wallet_status_loading,
    IS_WALLET_STATUS_OK: (state) => state.is_wallet_status_ok,
    IS_WALLET_STATUS_ERROR: (state) => state.is_wallet_status_error,
  },
  mutations: {
    SET_PROJECT: (state, payload) => (state.project = payload),

    SET_BANKS: (state, payload) => (state.banks = payload),
    SET_IS_BANKS_LOADING: (state, payload) =>
      (state.is_banks_loading = payload),
    SET_CHECKED_BANK: (state, payload) => (state.checked_bank = payload),
    SET_BANKS_ALL: (state, payload) => (state.banks_all = payload),
    SET_BANKS_MAIN: (state, payload) => (state.banks_main = payload),
    SET_BANKS_OTHERS: (state, payload) => (state.banks_others = payload),
    SET_IS_OTHER_BANK: (state, payload) => (state.is_other_banks = payload),

    SET_IS_WALLET_STATUS_LOADING:  (state, payload) => (state.is_wallet_status_loading = payload),
    SET_IS_WALLET_STATUS_OK:(state, payload) => (state.is_wallet_status_ok = payload),
    SET_IS_WALLET_STATUS_ERROR:(state, payload) => (state.is_wallet_status_error = payload),
  },
})

type Bank = {
  id: number
  name_ru: string
  name_en: string
  logo: string
  order: number
  sbp_member_id: string
}
