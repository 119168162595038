import { postZ } from '@/api/axios'
import { unknown_error } from '@/api/api_url'
import { DataTokenIs } from '@/types/auth'

const send_email_url = 'auth/by-email'
const token_url = 'auth/check-confirmation-code'
const token_is_url = 'auth/is'

const telegram_auth_url = 'auth/by-telegram'

const setTokenL = (token: string) => localStorage.setItem('token', token)
const removeTokenL = () => localStorage.removeItem('token')
const getTokenL = () => localStorage.getItem('token')

/**
 * Check confirmation code
 */
async function getTokenByTelegram(initData: any, project?: string) {
  removeTokenL()

  const response = await postZ(telegram_auth_url, {
    web_app_auth: initData,
    project,
  })

  if (response) {
    return response
  }
  return unknown_error
}

async function getToken(customer_id: string, code: number) {
  removeTokenL()
  const response = await postZ(token_url, {
    customer_id,
    code,
  })

  if (response) {
    return response
  }
  return unknown_error
}

function isAuth() {
  return !!getTokenL()
}

/**
 * Send Email, where confirmation code would be sent
 * If new email -> new account would be created
 * @param email
 */
async function postEmail(email: string) {
  removeTokenL()
  const response = await postZ(send_email_url, { email: email })

  if (response) {
    return response
  }
  return unknown_error
}

async function isTokenGood(token) {
  const response: DataTokenIs = await postZ(token_is_url, { token: token })
  if (response) {
    return response
  }
  return unknown_error
}

export {
  getToken,
  setTokenL,
  removeTokenL,
  getTokenL,
  isAuth,
  postEmail,
  isTokenGood,
  getTokenByTelegram,
}
