import { getZ } from '@/api/axios'

const assets_url = 'assets'

/**
 * Список всех валютных пар.
 */
async function getAssets(query?: string) {
  const response = await getZ(assets_url, { project: query })

  if (response) {
    return response
  }
  return []
}

export { getAssets }
