import {getCookie} from "@/api/cookies";


async function Seon(seon_w) {
  await seon_w.config({
    host: "getdeviceinf.com",
    session_id: getCookie('data'),
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
  });
  let seon_ddd: any = null
  await seon_w.getBase64Session(function (data) {
    seon_ddd = data
  });
  return seon_ddd
}

export {
  Seon
}
