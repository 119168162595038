import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'
import { checkOSTheme } from '@/utils/theme'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/core.css'
import './theme/grids.css'
import './theme/tags.css'
import './theme/ions.css'
import './theme/text.css'
import './theme/menu.css'
import './theme/test.css'

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'
import { store } from './store'

import loadScript from 'vue-plugin-load-script'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(loadScript)
  .use(checkOSTheme)

router.isReady().then(() => {
  app.mount('#app')
})
