import { getZ } from '@/api/axios'
import { unknown_error } from '@/api/api_url'

const success_url = 'payment_info'

const order_info_url = 'order_info'

async function PaymentInfo(order_id: string) {
  const response = await getZ(order_info_url, {
    order_id,
  })
  if (response) {
    return response
  }
  return unknown_error
}

export { PaymentInfo }
