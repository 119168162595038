import {reqCheckout, resCheckout} from "@/types/buycrypto";

async function Checkout(checkout: any, data: reqCheckout) {
  let response: resCheckout
  await checkout.createPaymentCryptogram(
    data
  ).then(
    (cryptogram: string) => response = {cryptogram: cryptogram, error: null}
  ).catch(
    (error) => response = {cryptogram: null, error: error}
  )
  return response
}

export {
  Checkout
}
