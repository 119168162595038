import {postZ} from "@/api/axios";
import {reqBuyCrypto, resBuyCrypto, resBuyCryptoQR, resBuyCryptoToken} from "@/types/buycrypto";
import {unknown_error} from "@/api/api_url";

const buy_crypto_url = 'buycrypto'

async function BuyCrypto(data: reqBuyCrypto) {
  const response: resBuyCrypto = await postZ(
    buy_crypto_url, data, true
  )
  if (response) {
    return response
  }
  return unknown_error
}

async function BuyCryptoToken(data: reqBuyCrypto) {
  const response: resBuyCryptoToken = await postZ(
    buy_crypto_url, data, true
  )
  if (response) {
    return response
  }
  return unknown_error
}

async function BuyCryptoQR(data: reqBuyCrypto) {
  const response: resBuyCryptoQR = await postZ(
    buy_crypto_url, data, true
  )
  if (response) {
    return response
  }
  return unknown_error
}

export {
  BuyCrypto,
  BuyCryptoQR,
  BuyCryptoToken
}
